.hero{
    display: flex;
    justify-content: space-around;
}

.col-1-hero, .col-2-hero{
    padding: 2rem;
}

.col-1-hero{

    display: flex;
    width: 50%;
    align-items: center;
    justify-content: center;
}

.col-1-hero h1{
    padding: 1em;
}

.col-2-hero{
    display: flex;
    width: 80%;
    align-items: center ;
    justify-content: center;
}

.col-2-hero p{
    font-size: 1.1rem; /* Relative size that adapts well */
    margin: 2% auto;
    padding: 1em;
    text-align:justify;
}

.col-1-aboutme{
    padding: 2.5rem;
    margin: 2rem;
}

.aboutMe{

}

.profile-picture {
    width: 200px; /* Adjust size as needed */
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.profile-picture img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@media (max-width: 1024px) {
    .hero {
        flex-direction: column;
        padding: 2rem;
        gap: 2rem;
    }

    .col-1-hero, .col-2-hero{
        width: auto;
        padding: 1rem;
    }

    .col-1-aboutme{
        padding: 0.5rem;
        margin: 2rem;
    }

    .profile-picture{
        width: 300px;
    }


}