/* Timeline.css */

.timeline-container {
    margin: 0 auto;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(15px);
    -webkit-backdrop-filter: blur(15px);
    border: 1px solid rgba(255, 255, 255, 0.2);
}

.timeline-event {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 20px;
}

.timeline-year {
    font-size: 1.5rem;
    font-weight: bold;
    color: #bbbbbb;
    margin-bottom: 20px;
    flex-shrink: 0;
}

.timeline-details {
    background-color: rgba(255, 255, 255, 0.1);
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(15px);
    -webkit-backdrop-filter: blur(15px);
    border: 1px solid rgba(255, 255, 255, 0.2);
    width: 95%;
}

.timeline-title {
    font-size: 1.8rem;
    margin: 0 0 10px;
    color: #e0e0e0;
}

.timeline-description {
    font-size: 1.2rem;
    color: #aaaaaa;
    margin-bottom: 10px;
}

.timeline-tags {
    display: flex;
    flex-wrap: wrap;
}

.timeline-tag {
    font-size: 0.9rem;
    padding: 5px 10px;
    margin: 5px;
    border-radius: 5px;
    color: #fff;
}


/* Tag Colors */
.yellow {
    background-color: #f1c40f;
}
.orange  {
    background-color: orange;
}
.blue {
    background-color: #3498db;
}
.red {
    background-color: #e74c3c;
}
.green {
    background-color: #2ecc71;
}
.purple {
    background-color: #9b59b6;
}

@media (max-width: 1024px) {
    .timeline-container{
        width: 100%;
        padding: 10px;
    }
    .timeline-details {
        width: 90%;
    }
}