.coming-soon-body {
    margin: 0;
    padding: 0;
    background-color: #f0f0f0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.coming-soon-container {
    text-align: center;
    background: white;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.coming-soon-title {
    font-size: 3rem;
    color: #333;
    margin-bottom: 20px;
}
.coming-soon-subtitle {
    font-size: 1.25rem;
    color: #555;
    margin-bottom: 0;
}
.coming-soon-description {
    font-size: 1rem;
    color: #555;
    margin-bottom: 0;
}

.coming-soon-title::after {
    content: ' 🚀';
}
.coming-soon-subtitle::after {
    content: '😉';
}

.coming-soon-description::before, .coming-soon-description::after {
    content: '✨ ';
}