body{
    padding: 0;
    margin:0;
    background-color: #121212;
    color: #e0e0e0;
    overflow-x: hidden;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-style: normal
}

