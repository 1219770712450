/* components/Header.css */

.header {
    width: auto;
    padding: 1rem;
    display: flex;
    justify-content: flex-end; /* Move content to the right */
    align-items: center;
    color: #fff;
    position: relative;
    z-index: 101; /* Ensure the header is above the page content */
}

.nav-container {
    width: auto;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.hamburger-icon {
    font-size: 2rem;
    cursor: pointer;
    color: white; /* Set color to black */
}

.socials{
    color: white;
    font-size:3rem;
    margin: 0.5rem;
}

.full-page-menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black; /* Make background slightly transparent for overlay effect */
    z-index: 100; /* Ensure the menu overlays everything else */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.close-icon {
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    font-size: 2rem;
    cursor: pointer;
    color:white;
}


.nav-list {
    list-style: none;
    text-align: center;
    padding: 0;
}

.nav-item {
    margin-bottom: 2rem;
}

.link {
    color: white;
    text-decoration: none;
    font-size: 2rem;
}
